import React from "react";
import {ISetting} from "../../../store/models/index.d";

const FormHeader = ({setting}: {setting: ISetting}) => {
    return (
        <div className="form-header">
            <div className="phone">
                <div>
                    <span>Бесплатный звонок по России</span>
                    <a href={`tel:${setting.phone}`} title={setting.phone}>
                        {setting.phone}
                    </a>
                </div>
            </div>
            <div className="email">
                <div>
                    <span>
                        Предложения о сотрудничестве {`\n`}
                        для поставщиков рекламы:
                    </span>
                    <a href={`mailto:${setting.email}`} title={setting.email}>
                        {setting.email}
                    </a>
                </div>
            </div>
        </div>
    )
}


export default FormHeader;
