import {postAuth, post, postApi, IResponse} from "../fetchUp";
import {ICalculateMediaPlan, ICalculateMediaPlan_AdditionalFilterValues, ICalculateMediaPlan_FilterValues} from "./index.d";

const cloneObject = (ovject: any): any => {
    var clone = JSON.stringify(ovject);
    return JSON.parse(clone);
};

const defAdditionalFilterValues = {
    regions: [],
    cities: [],
    channels: [],
    origintypes: [],
    formats: [],
    types: [],
    mediatypes: [],
    descriptions: [],
}

export const initialState: ICalculateMediaPlan = {
    loading: false,
    filterApply: false,
    filterValues: {
        id: 0,
        name: "",
        date: {
            from: "",
            to: "",
        },
        ...defAdditionalFilterValues
    },
    additionalFilterValues: defAdditionalFilterValues,
    table: {
        data: [],
        columns: {}
    },
    changesData: {}
}

export const calculateMediaPlan = {
	state: initialState,
	reducers: {
		update(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
        resetFilterValues(payload: any, rootState: any) {
            return dispatch.calculateMediaPlan.update(initialState)
        },
        setFilterValues(payload: any, rootState: any) {
            const {filterValues} = rootState.calculateMediaPlan;
            let data: ICalculateMediaPlan_FilterValues = Object.assign({}, cloneObject(filterValues), payload);
            return dispatch.calculateMediaPlan.update({filterValues: data})
        },
        filterApply(payload: any, rootState: any) {
            const {callback} = payload
            const {filterValues} = rootState.calculateMediaPlan;
            const promise = dispatch.calculateMediaPlan.update({loading: true, filterApply: true})

            promise.then(() => {
                postApi('api-out/get_results', {...filterValues, additional: defAdditionalFilterValues}, (response: IResponse, error: boolean) => {
                    if (!error) {
                        dispatch.calculateMediaPlan.update({
                            table: JSON.parse(response.data),
                            additionalFilterValues: defAdditionalFilterValues,
                            // changesData: {}
                        })
                        .then(() => {
                            dispatch.calculateMediaPlan.update({loading: false});
                        })
                    } else dispatch.calculateMediaPlan.update({loading: false});
                    if (callback) callback()
                });
            })
        },
        additionalFilterApply(payload: any, rootState: any) {
            const {field, values} = payload,
                {filterValues} = rootState.calculateMediaPlan;
            let additionalFilterValues: ICalculateMediaPlan_AdditionalFilterValues = cloneObject(rootState.calculateMediaPlan.additionalFilterValues);
            additionalFilterValues[field] = values;
            const promise = dispatch.calculateMediaPlan.update({loading: true, additionalFilterValues});
            promise.then(() => {
                let additional: ICalculateMediaPlan_AdditionalFilterValues = cloneObject(additionalFilterValues);
                additional.origintypes = additionalFilterValues.origintypes.flat();
                additional.mediatypes = additionalFilterValues.mediatypes.flat();
                postApi('api-out/get_results', {...filterValues, additional: additional}, (response: IResponse, error: boolean) => {
                    if (!error) {
                        dispatch.calculateMediaPlan.update({table: JSON.parse(response.data)})
                        .then(() => {
                            dispatch.calculateMediaPlan.update({loading: false});
                        })
                    } else dispatch.calculateMediaPlan.update({loading: false});
                });
            })
        },
        changesData(payload: any, rootState: any) {
            const {id, changeData} = payload;
            let changesData = Object.assign({}, rootState.calculateMediaPlan.changesData);

            const data = {
                itemId: id,
                amount: changeData.amount,
                due: changeData.due
            }
            postApi('api-out/get_price', data, (response: IResponse, error: boolean) => {
                if (!error && response.status === 200) {
                    changeData.price = response.data;
                    changesData[id] = changeData;
                    dispatch.calculateMediaPlan.update({changesData});
                }
            });
        },
        save(payload: any, rootState: any) {
            const {type, callback} = payload,
            data = {
                selected: rootState.calculateMediaPlan.changesData,
                name: rootState.calculateMediaPlan.filterValues.name,
                date: rootState.calculateMediaPlan.filterValues.date,
                id: rootState.calculateMediaPlan.filterValues.id,
                type
            }
            new Promise((resolve: any) => {
                if (rootState.user.token) {
                    postAuth(rootState.user.token, 'mediaplan/save', data, (response: IResponse, error: boolean) => {
                        resolve({response, error})
                    })
                } else {
                    post('mediaplan/save', data, (response: IResponse, error: boolean) => {
        				resolve({response, error})
        			})
                }
            }).then((data: any) => {
                const {response, error}: {response: IResponse, error: boolean} = data;
                if (!error && response.status === 200) {
                    if (callback) callback(response.data)
                }
            })
        },
        setMediaPlan(payload: any, rootState: any) {
            const {data, callback} = payload;
            const state = cloneObject(initialState);

            state.filterValues.id = data.id;
            state.filterValues.name = data.name;
            state.filterValues.date = data.date;
            state.changesData = data.changesData;

            if (data?.cities) state.filterValues.cities = data.cities;
            if (data?.regions) state.filterValues.regions = data.regions;
            if (data?.types) state.filterValues.types = data.types;

            dispatch.calculateMediaPlan.update(state)
            .then(() => {
                if (callback) callback()
            })
        },
        setSort(payload: {
            field: string,
            type: string
        }, rootState: any) {
            const {table} = rootState.calculateMediaPlan;
            let tableData: ICalculateMediaPlan["table"]["data"] = cloneObject(table.data)
            
            dispatch.calculateMediaPlan.update({
                table: {
                    ...table,
                    data: tableData.sort((a,b) => {
                        let valueA = a[payload.field],
                        valueB = b[payload.field];

                        if (payload.field === "city") {
                            valueA = a[payload.field].name;
                            valueB = b[payload.field].name;
                        }

                        if (payload.type === "DESC") {
                            if (valueA > valueB) return -1;
                            if (valueA < valueB) return 1;
                            return 0;
                        } else {
                            if (valueA < valueB) return -1;
                            if (valueA > valueB) return 1;
                            return 0;
                        }
                    })
                }
            })            
        }
    })
}
