import React from "react";
import {connect} from "react-redux";
import {Container, Grid} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import {BaseProps} from "../index.d";
import {ISetting} from "../../store/models/index.d";
import FormHeader from "./extra/formHeader";
import Form from "./extra/form";

interface IContacts extends BaseProps {
    setting: ISetting,
    fnSenfFeedback: any
}

const Contacts = ({setting, fnSenfFeedback}: IContacts) => {
    const [dialog, setDialog] = React.useState({show: false, message: ""}),
        handleShow = (message: string) => {
            setDialog({show: true, message: message})
        },
        handleClose = () => setDialog({...dialog, show: false})

    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div id="contacts">
            <Container fixed>
                <Grid
                    className="grid-container"
                    container>
                    <Grid item xs={7}>
                        <FormHeader
                            setting={setting}/>
                        <Form
                            dialogShow={handleShow}
                            fnSenfFeedback={fnSenfFeedback}/>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="bg-contacts" />
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={dialog.show}
                keepMounted
                onClose={handleClose}>
                    <DialogContent>
                        <div className="dialog-grid">
                            <div className="images-grid">
                                <div />
                            </div>
                            <div className="description-grid">
                                <div>
                                    <div className="title">Спасибо! Ваша заявка успешно отправлена.</div>
                                    <div
                                        className="message"
                                        dangerouslySetInnerHTML={{
                                            __html: dialog.message
                                        }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
            </Dialog>
        </div>
    )
}

const mapState = (state: any) => {
    return {
        setting: state.setting,
    };
};

const mapDispatch = (dispatch: any) => {
    return {
        fnSenfFeedback: (formValues: any, callback: any) => dispatch.setting.senfFeedback({formValues, callback}),
    };
}

export default connect(mapState, mapDispatch)(Contacts);


// import React from "react";
// import {connect} from "react-redux";
// import {Container, Grid} from '@material-ui/core';

// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';

// import {BaseProps} from "../index.d";
// import {ISetting} from "../../store/models/index.d";
// import FormHeader from "./extra/formHeader";
// import Form from "./extra/form";

// interface IContacts extends BaseProps {
//     setting: ISetting,
//     fnSenfFeedback: any
// }

// class Contacts extends React.PureComponent<IContacts> {

//     readonly state = {
//         dialog: {
//             show: false, message: ""
//         }
//     }

//     componentDidMount = () => {
//         window.scrollTo(0, 0);
//     }

//     setDialog = (dialog: any) => this.setState({dialog})

//     handleClose = () => {
//         const {dialog} = this.state;
//         this.setDialog({...dialog, show: false})
//     }

//     handleShow = (message: string) => {
//         this.setDialog({show: true, message})
//     }

//     rendeer = () => {
//         const {setting, fnSenfFeedback} = this.props,
//         {dialog} = this.state;
//         return (
//             <div id="contacts">
//                 <Container fixed>
//                     <Grid
//                         className="grid-container"
//                         container>
//                         <Grid item xs={7}>
//                             <FormHeader
//                                 setting={setting}/>
//                             <Form
//                                 dialogShow={this.handleShow}
//                                 fnSenfFeedback={fnSenfFeedback}/>
//                         </Grid>
//                         <Grid item xs={5}>
//                             <div className="bg-contacts" />
//                         </Grid>
//                     </Grid>
//                 </Container>
//                 <Dialog
//                     open={dialog.show}
//                     keepMounted
//                     onClose={this.handleClose}>
//                         <DialogContent>
//                             <div className="dialog-grid">
//                                 <div className="images-grid">
//                                     <div />
//                                 </div>
//                                 <div className="description-grid">
//                                     <div>
//                                         <div className="title">Спасибо! Ваша заявка успешно отправлена.</div>
//                                         <div
//                                             className="message"
//                                             dangerouslySetInnerHTML={{
//                                                 __html: dialog.message
//                                             }}>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </DialogContent>
//                 </Dialog>
//             </div>
//         )
//     }
// }

// const mapState = (state: any) => {
//     return {
//         setting: state.setting,
//     };
// };

// const mapDispatch = (dispatch: any) => {
//     return {
//         fnSenfFeedback: (formValues: any, callback: any) => dispatch.setting.senfFeedback({formValues, callback}),
//     };
// }

// export default connect(mapState, mapDispatch)(Contacts);
