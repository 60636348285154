import React, {Component} from "react";
import {connect} from "react-redux";
import {BaseProps} from "../index.d";

import Slider from "../../components/slider";
import AdvertisingCatalog from "../../components/advertisingCatalog";
import WorkInfo from "../../components/workInfo";
import Figures from "../../components/figures";
import OurAdvantages from "../../components/ourAdvantages";
import Reviews from "../../components/reviews";
import {ISetting, IHome} from "../../store/models/index.d";

interface IHomeScreen extends BaseProps {
    setting: ISetting,
    home: IHome
}

class HomeScreen extends Component<IHomeScreen> {

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render = () => {
        const {home} = this.props,
            {slider, advertisingCatalog, workInfo,
                figures, ourAdvantages, reviews, loading} = home;

        return (
            <div>
                {!loading?(
                    <>
                        <Slider
                            {...slider}/>
                        <AdvertisingCatalog
                            {...advertisingCatalog}/>
                        <WorkInfo
                            {...workInfo}/>
                        <Figures
                            {...figures}/>
                        <OurAdvantages
                            items={ourAdvantages}/>
                        <Reviews
                            {...reviews}/>
                    </>
                ): null}
            </div>
        )
    }
}

const mapState = (state: any) => {
    return {
        setting: state.setting,
        home: state.home
    };
};

export default connect(mapState)(HomeScreen);
