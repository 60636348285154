import {
    ICalculateMediaPlan_Items as IData,
    ICalculateMediaPlan_Columns as IColumns,
    ICalculateMediaPlan_AdditionalFilterValues
} from "store/models/index.d";

import scrollbarSize from 'dom-helpers/scrollbarSize';

export interface ITable {
    width: number,
    loading: boolean,
    filterApply: boolean,
    table: {
        data: IData[],
        columns: IColumns,
    },
    fnAdditionalFilterApply: any,
    additionalFilterValues: ICalculateMediaPlan_AdditionalFilterValues,
    handleTableDialog: any
}

export const getColumns = (options: IColumns) => {
    let columns = [
        // {name: "checkbox", caption: <span className="GridColumnCheckbox"></span>, width: 50, options: [], filterName: ""},
        {name: "checkbox", caption: "", width: 50, options: [], filterName: "", sorteble: false},
        // {name: "region", caption: "Регион", maxWidth: 200, options: options.regions, filterName: "regions", sorteble: false},
        {name: "city", caption: "Город", maxWidth: 200, options: options.cities, filterName: "cities", sorteble: true},
        {name: "serviceType", caption: "Направление", width: 150, options: options.types, filterName: "types", sorteble: false},
        {name: "mediaType", caption: "Вид носителя", width: 150, options: options.mediaTypes, filterName: "mediatypes", sorteble: false},
        {name: "name", caption: "Описание", options: options.descriptions, filterName: "descriptions", sorteble: false},
        {name: "channel", caption: "Носитель", width: 150, options: options.channels, filterName: "channels", sorteble: false},
        {name: "size", caption: "Формат", width: 120, options: options.formats, filterName: "formats", sorteble: false},
        {name: "due", caption: "Сроки, дни", width: 137, options: [], filterName: "", sorteble: false},
        // {name: "amount", caption: "Количество, шт", width: 137, options: [], filterName: "", sorteble: false},
        // {name: "amount", caption: "Количество, выходов в день", width: 137, options: [], filterName: "", sorteble: false},
        {name: "amount", caption: "Количество", width: 137, options: [], filterName: "", sorteble: false},
        {name: "price", caption: "Стоимость, ₽", width: 137, options: [], filterName: "", noBorder: true, sorteble: true},
        {name: "sale", caption: "", width: 50, options: [], filterName: "", noBorder: true, sorteble: false},
        {name: "comment", caption: "Комментарий", options: [], filterName: "comment", sorteble: false},
    ];

    if (scrollbarSize() > 0) {
        columns.push({name: "scrollbar", caption: "", width: scrollbarSize(), options: [], filterName: "", noBorder: true, sorteble: false})
    }
    return columns;
}

export const getColumnWidth = (grid: any, columns: any, defaultValue: number): number => {
    const {index} = grid;
    // debugger;
    if (columns[index]?.maxWidth && columns[index].maxWidth <= defaultValue) {
        return columns[index].maxWidth;
    } else if (columns[index]?.width) {
        return columns[index].width;
    }
    return defaultValue;
}

export const cetFixedColumnWidth = (columns: any): {count: number, maxCount: number, width: number, maxWidth: number} => {
    let data: {count: number, maxCount: number, width: number, maxWidth: number} = {
        count: 0,
        maxCount: 0,
        width: 0,
        maxWidth: 0,
    }

    columns.forEach((column: any) => {
        if (column.maxWidth) {
            data.maxCount += 1;
            data.maxWidth += column.maxWidth;
        } else if (column.width) {
            data.count += 1;
            data.width += column.width;
        }
    });

    return data;
}
