export interface IHome {
    slider: any,
    advertisingCatalog: any,
    workInfo: any,
    figures: any,
    ourAdvantages: any[],
    reviews: any,
    cases: any[],
    loading: boolean
}

export interface ISetting {
	meta_title: string,
	meta_description: string,
	meta_keywords: string,
	email: string,
	phone: string,
	address: string,
	user_agreement_link: string,
	figuresAboutUsTitle: string,
	figuresAboutUsSignature: string,
	presentation: string,
	instruction: string,
}

export enum EnumAuthForm {
    Login = 1,
    CheckIn = 2
}

export interface IAuthItemForm {
    name: string,
    label: string,
    value: string,
    type: string
}

export interface IAuthorization {
    dialog: {
        show: boolean
        formType: EnumAuthForm
    },
    forms: {
        [index: string]: any
        login: IAuthItemForm[]
    }
}

export interface IMediaPlan {
    id: number,
    name: string,
    status: string,
    date: string
}

export interface IUser {
    [index: string]: any,
    token: string,
    name: string,
    surname: string,
    company: string,
    phone: string,
    email: string,
    loading: boolean,
    mediaPlans: {
        items: IMediaPlan[],
        totalPage: number,
        currentPage: number,
    },
}

export interface ICalculateMediaPlan {
    loading: boolean,
    filterApply: boolean,
    filterValues: ICalculateMediaPlan_FilterValues,
    additionalFilterValues: ICalculateMediaPlan_AdditionalFilterValues,
    table: {
        data: ICalculateMediaPlan_Items[],
        columns: ICalculateMediaPlan_Columns,
    }
    changesData: ICalculateMediaPlan_ChangesData
}

export interface ICalculateMediaPlan_FilterValues extends ICalculateMediaPlan_AdditionalFilterValues {
    id: number
    name: string,
    date: {
        from: string,
        to: string,
    },
}

export interface ICalculateMediaPlan_AdditionalFilterValues {
    regions: number[],
    cities: number[],
    channels: number[],
    origintypes: number[],
    formats: number[],
    types: number[],
    mediatypes: number[],
    descriptions: string[],
    [index: string]: any,
}

export interface ICalculateMediaPlan_Columns {
    [index: string]: {
        id: number | string,
        name: string
    }[]
}

export interface ICalculateMediaPlan_ChangesData {
    [index: number]: ICalculateMediaPlan_ChangeData
}

export interface ICalculateMediaPlan_ChangeData {
    selected: boolean,
    amount: number,
    due: number,
    price: number,
    data: ICalculateMediaPlan_Items,
    [index: string]: any
}

export interface ICalculateMediaPlan_Items {
    [index: string]: any,
    id: number,
    region: ICalculateMediaPlan_Item, // Регион
    city: ICalculateMediaPlan_Item, // Город
    channel: ICalculateMediaPlan_Item, // Направление
    origintypes: ICalculateMediaPlan_Item, // Носитель
    mediaType: ICalculateMediaPlan_Item, // Носитель
    name: string, // Описание
    size: string, // Формат
    sale: boolean, // Акция
    due: ICalculateMediaPlan_Value, // Сроки, дни
    amount: ICalculateMediaPlan_Value, // Количество, шт
    price: number, // Количество, шт
    images: string[] // Картинки
    [index: string]: any,
}

export interface ICalculateMediaPlan_Item {
    id: number,
    name: string,
}

export interface ICalculateMediaPlan_Value {
    min: number,
    step: number,
}
