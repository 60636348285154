import {post, IResponse} from "../fetchUp";
import {IHome} from "./index.d";

const initialState: IHome = {
    slider: {},
    advertisingCatalog: {
        title: "",
        items: []
    },
    workInfo: {},
    figures: {},
    ourAdvantages: [],
    reviews: {},
    cases: [],
    loading: true
}

const home = {
	state: initialState,
	reducers: {
		update(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
		init(payload: any, rootState: any) {
        	post('home', {}, (response: IResponse, error: boolean) => {
				if (!error) {
                    let update: IHome = Object.assign({}, response.data, {loading: false});
                    dispatch.home.update(update)
                }
			})
        },
    })
}

export default home;
