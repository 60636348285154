import React from 'react'
import {connect} from "react-redux";
// import {Link, NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import {Container} from '@material-ui/core';

import routes from "../../router/routes";
import {TLink} from "../../router/index.d";
import {IUser} from "../../store/models/index.d";

const getHeadeLinks = () => {
    let links: TLink[] = Object.entries(routes).map((arr: any[]) => {
        return arr[1];
    });
    return links;
}

const Header = ({user, fnAuthDialog}: {user: IUser, fnAuthDialog: any}) => {
    const [count, setCount] = React.useState(0);
    return (
        <header id="header">
            <Container fixed>
                <div className="grid-container">
                    <div className="grid-logo">
                        <Link to="/">
                            <div></div>
                        </Link>
                    </div>
                    <div className="grid-menu">
                        <ul>
                            {getHeadeLinks().map((val: TLink, idx: number) => {
                                let selected = val.to === window.location.pathname ? true : false;
                                return (
                                    <li key={idx}>
                                        <Link to={val.to} className={selected?"selected":""} onClick={() => setCount(count+1)}>
                                            {val.name}
                                        </Link>
                                    </li>
                                )
                                // return (
                                //     <li key={idx}>
                                //         <NavLink
                                //             to={val.to}
                                //             activeClassName="selected">
                                //                 {val.name}
                                //             </NavLink>
                                //     </li>
                                // )
                            })}
                        </ul>
                    </div>
                    <div className="grid-personalArea">
                        {user.token?(
                            <Link to="/cabinet">
                                {`${user.surname} ${user.name}`}
                            </Link>
                        ):(
                            <button onClick={() => fnAuthDialog({show: true})}>
                                Войти
                            </button>
                        )}
                    </div>
                </div>
            </Container>
        </header>
    )
}

const mapState = (state: any) => {
    return {
        user: state.user,
    };
}, mapDispatch = (dispatch: any) => {
    return {
        fnAuthDialog: (data: any) => dispatch.authorization.dialog(data),
    };
}

export default connect(mapState, mapDispatch)(Header);
