import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import Carousel from 'nuka-carousel';
import {TCatalog, TFilterData, TFilterValues, Option, getCityName} from "../index.d";
import CityAuthoSelect from "./cityAuthoSelect";

type Props = {
    catalog: TCatalog,
    images: string[],
    filterData: TFilterData,
    filterValues: TFilterValues,
    handlerChangeCity: any,
}

interface State {
    search: string,
    options: Option[],
    selectedValues: Option[],
}

class Description extends Component<Props, State> {

    readonly state = {
        options: [],
        selectedValues: [],
        search: ""
    }

    componentDidMount = () => {
        const {filterData, filterValues} = this.props;
        this.setState({
            search: getCityName(filterData, filterValues)
        })
    }

    setValue = (event: any, value: Option) => {
        this.setState({search: value.name});
        this.props.handlerChangeCity(value);
    }

    onChangeText = (event: any, isFocus: boolean = false) => {
        if (event?.target) this.setState({search: event.target.value})
        else if (isFocus) this.setState({search: ""})
    }

    render = () => {
        const self = this,
            {search} = self.state,
            {catalog, images, filterData} = self.props;

        return (
            <Grid
                container justify="center"
                alignItems="center" spacing={3}>
                <Grid item xs={6}>
                    <div className="title">
                        {catalog.title}
                    </div>
                    <CityAuthoSelect
                        search={search}
                        filterData={filterData}
                        onChange={self.setValue}
                        onChangeText={self.onChangeText}/>
                    <div className="description">
                        {catalog.description}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="carousel">
                        <Carousel
                            defaultControlsConfig={{
                                pagingDotsContainerClassName: "dotsContainer"
                            }}
                            renderCenterLeftControls={({previousSlide}) => (
                                <button className="control-prev" onClick={previousSlide} />
                            )}
                            renderCenterRightControls={({nextSlide}) => (
                                <button className="control-next" onClick={nextSlide} />
                            )}>
                            {images.map((src: string, idx: number) => {
                                return <img key={idx} src={src} alt={catalog.title} />
                            })}
                        </Carousel>
                    </div>
                </Grid>
            </Grid>
        )
    }
}
export default Description;
