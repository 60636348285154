import React from "react"
import {withRouter} from "react-router-dom";
import Helmet from "react-helmet";

const initialState = {
    title: "InGood - Маркетплейс рекламы",
    description: "Хотите рекламное место на самых заметных местах в городе? Готовы быть узнаваемыми буквально на каждом шагу? Закажите наружную рекламу у нас в InGood! Общественный транспорт, торговые сети, лифты бизнес-центров и жилых домов - вас увидят все. Звоните: 8 (800) 222-53-91"
}

class HelmetAap extends React.Component<any> {

    readonly state = initialState;
  
    private unlisten = () => {}

    componentWillMount = () => {
        this.unlisten = this.props.history.listen((location: any, action: any) => {
            if  (location.pathname === "/cases") {
                this.setState({
                    title: "InGood - Готовые кейсы нашей компании",
                    describe: "Реклама, которую мы, с любовью, уже сделали. Закажите наружную рекламу у нас в InGood! Общественный транспорт, торговые сети, лифты бизнес-центров и жилых домов - вас увидят все. Звоните: 8 (800) 222-53-91",
                })
            } else {
                this.setState(initialState)
            }
        });
    }

    componentWillUnmount = () => {
        this.unlisten();
    }

    render() {
        const {title, description} = this.state;
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <script type="application/ld+json"> 
                        {`{
                            "@context": "http://www.schema.org",
                            "@type": "Organization",
                            "name": "${title}",
                            "description": "${description}",
                            "url": "https://ingood.ru/",
                            "logo": "https://ingood.ru/static/media/logo.6a38cc86.png",
                            "image": "https://ingood.ru/static/media/logo.6a38cc86.png",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": " 8 (800) 222-53-91",
                                "contactType": "buying_admin@ingood.ru"
                            }
                        }`}
                    </script>
                </Helmet>
                <div>
                    {this.props.children}
                </div>
            </>
        );
    }
}

export default withRouter(HelmetAap);