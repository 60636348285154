import React from "react";
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import RouterItem from "./extra/routerItem";
import Header from '../components/header';
import Footer from "../components/footer";

import HomeScreen from '../screens/home';
import CatalogScreen from '../screens/catalog';
import CatalogItemScreen from '../screens/catalogItem';
import ContactsScreen from '../screens/contacts';
import CabinetScreen from '../screens/cabinet';
import CalculateMediaPlanScreen from '../screens/calculateMediaPlan';
import EditMediaPlanScreen from '../screens/editMediaPlan';
import Cases from "screens/cases";

import Helmet from "../helmet";

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Helmet>
                <Header />
                <div className="app-container">
                    <Switch>
                        <Route path="/catalog-sale/(:id)?" children={<RouterItem extraData={{catalogSale: true}} component={CatalogScreen}/>} />
                        <Route path="/catalog/:id" children={<RouterItem extraData={{catalogSale: false}} component={CatalogItemScreen}/>} />
                        <Route path="/cabinet/media-plan/:id" children={<RouterItem component={EditMediaPlanScreen}/>} />
                        <Route path="/catalog" children={<RouterItem extraData={{catalogSale: false}} component={CatalogScreen}/>} />
                        <Route path="/contacts" children={<RouterItem component={ContactsScreen}/>} />
                        <Route path="/cabinet" children={<RouterItem component={CabinetScreen}/>} />
                        <Route path="/calculate-media-plan" children={<RouterItem component={CalculateMediaPlanScreen}/>} />
                        <Route path="/cases" children={<RouterItem component={Cases}/>} />
                        <Route path="/" children={<RouterItem component={HomeScreen}/>} />
                    </Switch>
                </div>
                <Footer />
            </Helmet>
        </BrowserRouter>
    )
}

export default Router;
